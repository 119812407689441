<template>
  <el-dialog  title="服务标签库"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="700px"
              @closed="closeModal">
    <div style="width: 100%">
      <ch-button @click="openAddTag">新增</ch-button>
      <ch-table :render-option="tableOption" :data="tableList"
                :loading="isTableLoading"
                :props="{width: '100%', height: '300px', size: 'mini'}">
        <template v-slot:handle="scope">
          <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
          <ch-button type="delete" @click="handleDelete(scope.row)">删除</ch-button>
        </template>
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-page
          type="old"
          ref="page"
          :getListFunction="getList"></ch-page>
    </div>
    <service-tag-modal ref="serviceTagModal" :get-list-function="getList" />
  </el-dialog>
</template>
<script>
import { handleDelete } from "@/utils/utils";
const serviceTagModal = () => import("./serviceTagModal.vue")
export default {
  components:{
    serviceTagModal
  },
  data(){
    return {
      isOpenModal:false,
      isTableLoading:false,
      tableList:[],
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "名称", prop: "name", showOverflowTooltip: true,},
        { column: "slot", label: "操作", slotName: "handle", width: "150" , fixed: "right" },
      ];
    },
  },
  methods:{
    openTagsList(){
      this.isOpenModal = true
      this.getList()
    },
    getList(){
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmGoodsServeTagLibrary/list").then((res) => {
        this.tableList = res.rows
      }).finally(() => {this.isTableLoading = false});
    },
    openAddTag(){
      this.$refs.serviceTagModal.openAdd()
    },
    openEdit(row){
      this.$refs.serviceTagModal.openEdit(row)
    },
    handleDelete(row){
      handleDelete("/hm/hmGoodsServeTagLibrary/delete/", row.id).then(() => {
        this.getList()
      })
    },
    closeModal(){
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>